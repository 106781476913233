import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  Grid,
  Typography,
  Fade,
  Box,
  Backdrop
} from '@mui/material';
import axios from "axios";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';
import { toLocaleLanguage } from "../../../main/components/translator/translation-helper";
import LanguageContext from "../../../main/components/translator/languageContext";

const NumberGridModal = ({ categoryId, raceId, opened, handleOpen, selectedNumber }) => {
  const [pagedGridData, setPagedGridData] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedGridNumber, setSelectedGridNumber] = useState(null);
  const { currentLang } = useContext(LanguageContext);


  const handleClose = () => {
    handleOpen(false);
  };

  const generateNumbers = () => {
    const numbers = [];
    for (let i = 0; i < pagedGridData[currentPage]?.data?.length ?? 0; i++) {
      let currentNumber = pagedGridData[currentPage]?.data[i];
      const isSelected = currentNumber.number === selectedGridNumber;
      numbers.push(
        <Grid item key={i} xs={2} md ={2} style={{
          margin: '3px',
          padding: '10px',
          textAlign: 'center',
          fontSize: '1.5rem',
          backgroundColor: isSelected ? "#41c2bf" : currentNumber.taken ? "#be4141" : "#83ad58",
          boxShadow: "0 5px 10px rgba(0,0,0,.1)",
          borderRadius: "4px",
          cursor: !currentNumber.taken && "pointer",
          transition: "background-color 0.6s ease",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          outline: isSelected ? "2px solid black" : "none",
        }}
              onClick={() => !currentNumber.taken && handleGridItemClick(currentNumber.number)}>
          {currentNumber.number}
        </Grid>
      );
    }
    return numbers;
  };

  const handleGridItemClick = (number) => {
    setSelectedGridNumber(number);
    selectedNumber(number);
  };

  const loadData = async () => {
    const getFreeCategoryStartNumbersResponse = await axios.post("/api/race/getFreeCategoryStartNumbers", {
      categoryId: categoryId,
      raceId: raceId
    });
    setPagedGridData(getFreeCategoryStartNumbersResponse.data.pagedCategoryContextList)
    setPageSize(getFreeCategoryStartNumbersResponse.data.pagedCategoryContextList.length)
  }

  useEffect(() => {
    if (categoryId !== undefined && raceId !== undefined) {
      setCurrentPage(0);
      loadData();
    } else {
      setPagedGridData([]);
      setSelectedGridNumber(null)
      setCurrentPage(0)
    }
  }, [categoryId]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={opened}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={opened}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width:{xs: "90%", md: "50%"},
          maxWidth: "600px",
          height: "470px",
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 0.5,
        }}>
          <CloseIcon style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }} onClick={handleClose}></CloseIcon>
          <Typography id="transition-modal-title" variant="h6" component="h2" style={{ textAlign: "center", paddingBottom: "20px", paddingTop: "10px"}}>
            {toLocaleLanguage("CHOOSE_START_NUMBER", currentLang)}
          </Typography>
          {categoryId !== undefined && raceId !== undefined ? (
            <>
              <Grid container spacing={1} style={{ display: "flex", justifyContent: "center", marginLeft: "-4px"}}>
                {generateNumbers()}
              </Grid>
              <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "20px"}}>
                <NavigateBeforeIcon
                  style={{ cursor: currentPage > 0 && "pointer", color: currentPage > 0 ? "green" : "grey" }}
                  onClick={() => currentPage > 0 && setCurrentPage(currentPage - 1)}
                />
                <div >{currentPage + 1}</div>
                <NavigateNextIcon
                  style={{ cursor: currentPage < pageSize - 1 && "pointer", color: currentPage < pageSize - 1 ? "green" : "grey" }}
                  onClick={() => currentPage < pageSize - 1 && setCurrentPage(currentPage + 1)}
                />
              </div>
            </>
          ) : (
            <div style={{ textAlign: "center", paddingTop: "20px" }}>
              {toLocaleLanguage("NO_CATEGORY_DEFINED", currentLang)}
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default NumberGridModal;
