import "./styles/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumnRaces } from "../../data/datatable-source";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import DoneIcon from '@mui/icons-material/Done';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DeleteModal from "../modal/deleteModal";
import { csCZ } from "@mui/x-data-grid/locales";



const RaceDatatableAdmin = () => {
  const [data, setData] = useState([]);
  const [multipleRowsSelected, setMultipleRowsSelected] = useState(false);
  const [checkBoxParams, setCheckBoxParams] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [passedText, setPassedText] = useState("");
  const [deleteMany, setDeleteMany] = useState(false);
  const [raceToDeleteId, setRaceToDeleteId] = useState("");
  const handleCheckboxSelection = (params) => {
    params.length > 0 ? setMultipleRowsSelected(true) : setMultipleRowsSelected(false);
    setCheckBoxParams(params);
  }

  const handleDeleteMany = async () => {
    for (let id of checkBoxParams) {
      await axios.get("/api/race/delete", {
        params: {
          id: id
        }
      })
    }
    await fetchData();
  }

  const handleDelete = async (id) => {
    await axios.get("/api/race/delete", {
      params: {
        id: id
      }
    })
    setData(data.filter((item) => item.id !== id));

  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    return date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
  };

  const fetchData = async () => {
    try {

      let raceListResponse = await axios.post("/api/race/list", {});
      let raceIdList = raceListResponse.data.itemList.map(race => race._id)
      let racerListResponse = await axios.post("/api/racer/list", {
        "filterList": [
          {
            "key": "raceId",
            "valueList": raceIdList
          }
        ]
      })
      let data = raceListResponse.data.itemList.map((item) => {
        return {
          id: item._id,
          name: item.name,
          date: formatDate(item.start),
          maxRacers: item.maxRacers,
          registeredRacers: racerListResponse.data.itemList.filter(racer => racer.raceId === item._id).length,
          closed: item.closed
        };
      })
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenModal = (text, id, many, event) => {
    event.stopPropagation(); // Stop event propagation
    setPassedText(text);
    setRaceToDeleteId(id);
    setModalOpened(true);
    setDeleteMany(many);
  }

  const customColumns = [
    {
      field: "closed",
      headerName: "Uzavřeno",
      width: 100,
      filterable: false,
      renderCell: (params) => {
        return params.row.closed ? <DoneIcon/> : <DoNotDisturbIcon/>;
      },
    },
    {
      field: "action",
      headerName: "Akce",
      width: 400,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/admin/races/view/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">Zobrazit</div>
            </Link>
            <Link to={`/admin/races/update/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="editButton">Upravit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={(event) => handleOpenModal("Opravdu chcete smazat závod: " + params.row.name + " ?", params.row.id, false, event)}
            >
              Smazat
            </div>

            <Link to={`/admin/racers/new/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="registerButton">Registrovat závodníka</div>
            </Link>
          </div>
        );
      },
    }
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Seznam závodů
        <div className="links">
          {multipleRowsSelected && (
            <Button variant="outlined" className="linkDeleteMany" onClick={(event) => handleOpenModal("Opravdu chcete smazat označené závody?", null, true, event)}>
              Smazat označené
            </Button>
          )}
          <Link to="/admin/races/new">
            <Button
              variant="outlined"
              className="linkCreate"
              component="label"
            >
            Vytvořit závod
            </Button>
          </Link>
        </div>
      </div>
      <DataGrid
        rows={data}
        columns={userColumnRaces.concat(customColumns)}
        checkboxSelection
        onRowSelectionModelChange={handleCheckboxSelection}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
      <DeleteModal
        text={passedText}
        handleOpen={(open) => setModalOpened(open)}
        opened={modalOpened}
        id={raceToDeleteId}
        handleDelete={deleteMany ? handleDeleteMany : handleDelete}
      />
    </div>
  );
};

export default RaceDatatableAdmin;