import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import LanguageContext from "../../components/translator/languageContext";
import { toLocaleLanguage } from "../translator/translation-helper";


function Footer() {
    const { currentLang } = useContext(LanguageContext);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2.5}
            color="white"
            style={{ background: "#303841", paddingBottom: 40, paddingTop: 20 }}
        >
            <Grid item xs={12} md={6} style={{}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    color="white"

                >
                    <Grid item xs={10} style={{}}>
                        <h1>
                            {toLocaleLanguage('CONTACT', currentLang)}
                        </h1>
                    </Grid>
                    <Grid item xs={10} style={{}}>
                        <CallIcon /> +420 603 423 088
                    </Grid>
                    <Grid item xs={10} style={{}}>
                        <PlaceIcon /> Ostrava
                    </Grid>
                    <Grid item xs={10} style={{}}>
                        <EmailIcon /> casomira@all4el.cz
                    </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    color="white"
                >
                    <Grid item xs={10} style={{}}>
                        <h6>
                            Created by: Petr Turek & Jan Skácel
                        </h6>
                    </Grid>
                    <Grid item xs={10} style={{}}>
                        <h6>
                            © casomira.eu. All rights reserved.
                        </h6>
                    </Grid>
                    <Grid item xs={10} style={{}}>
                        <CallIcon /> +420 608 480 803
                    </Grid>
                    <Grid item xs={10} style={{}}>
                        <PlaceIcon /> Ostrava
                    </Grid>
                    <Grid item xs={10} style={{}}>
                        <EmailIcon /> petr.turek9@gmail.com
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    );

}

export default Footer;
