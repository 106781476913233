import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid } from "@mui/material";
import toast from 'react-hot-toast';
import axios from "axios";
import LanguageContext from "../translator/languageContext";
import { toLocaleLanguage } from "../translator/translation-helper";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Default width for both PC and mobile
  maxWidth: 450, // Maximum width for PC
  maxHeight: 600,
  bgcolor: '#3A4750',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  '@media (max-width: 600px)': { // Media query for mobile devices
    width: '90%' // Adjust width for mobile devices
  }
};

export default function ContactModal({ opened, handleOpen, racerName, racerSurname, startNumber, raceName }) {
  const [formData, setFormData] = useState({
    email: '',
    telephone: '',
    message: ''
  });
  const { currentLang } = useContext(LanguageContext);

  const handleCloseAndClearInputData = () => {
    handleOpen(false);
    setFormData({
      email: '',
      telephone: '',
      message: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEmailSend = async () => {
    try {
      let emailData = {
        name: racerName,
        surname: racerSurname,
        startNumber: startNumber,
        raceName: raceName,
        email: formData.email,
        telephone: formData.telephone,
        message: formData.message
      }
      let sendEmailResponse = await axios.post("/api/sendEmail", emailData);
      if (sendEmailResponse.status === 200) {
        toast.success(toLocaleLanguage("EMAIL_SENT", currentLang));
      } else {
        toast.error(toLocaleLanguage("EMAIL_NOT_SENT", currentLang))
      }
    } catch (error) {
      toast.error(toLocaleLanguage("EMAIL_NOT_SENT", currentLang))
    }
  }

  const handleSubmit = () => {
    if (formData.email === '') {
      toast.error(toLocaleLanguage("ENTER_EMAIL", currentLang));
      return;
    }
    const emailRegex = /^\s*([^\s@]+@[^\s@]+\.[^\s@]+)\s*$/;
    if (!emailRegex.test(formData.email)) {
      toast.error(toLocaleLanguage("ENTER_VALID_EMAIL", currentLang));
      return;
    }

    if (formData.telephone === '') {
      toast.error(toLocaleLanguage("ENTER_TELEPHONE", currentLang));
      return;
    }
    if (formData.telephone.replace(/\D/g, '').length < 7) {
      toast.error(toLocaleLanguage("ENTER_VALID_TELEPHONE", currentLang));
      return;
    }

    if (formData.message === '') {
      toast.error(toLocaleLanguage("ENTER_MESSAGE", currentLang));
      return;
    }

    handleEmailSend();
    handleCloseAndClearInputData();
  };


  return (
    <div>
      <Modal
        open={opened}
        onClose={handleCloseAndClearInputData}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="right"
            spacing={3}
            color="white"
          >
            <Grid item xs={12}>
              <h4>{toLocaleLanguage("CHANGE_REQUEST_MODAL", currentLang)}</h4>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="email">{toLocaleLanguage("YOUR_EMAIL", currentLang)}</label>
              <br/>
              <input type="email" id="email" name="email" style={{ width: "100%" }} onChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="telephone">{toLocaleLanguage("YOUR_PHONE_NUMBER", currentLang)}</label>
              <br/>
              <input type="tel" id="telephone" name="telephone" style={{ width: "100%" }} onChange={handleChange}/>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="message">{toLocaleLanguage("MESSAGE", currentLang)}</label>
              <br/>
              <textarea id="message" name="message" style={{ width: "100%", minHeight: "150px", maxHeight: "250px" }} onChange={handleChange}></textarea>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button variant="contained" color="primary" style={{ fontSize: 12 }} onClick={handleSubmit}>{toLocaleLanguage("SEND", currentLang)}</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
