import React, { createContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(['language']);
  const [currentLang, setCurrentLang] = useState(cookies.language || 'cz');

  useEffect(() => {
    if (!cookies.language) {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      setCookie('language', 'cz', { path: '/', expires: expirationDate });
      setCurrentLang('cz');
    }
  }, []);

  const changeLanguage = (newLang) => {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);
    setCookie('language', newLang, { path: '/', expires: expirationDate });
    setCurrentLang(newLang);
  };

  return (
    <LanguageContext.Provider value={{ currentLang, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
