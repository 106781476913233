import React, { useRef, useEffect, useContext } from "react";
import LanguageContext from "../../components/translator/languageContext";
import { toLocaleLanguage } from "../../components/translator/translation-helper";
import { Grid } from '@mui/material';
import Navbar from "../../components/navbar/navbar.jsx";
import ImageGallery from "../../components/imageGallery/imageGallery";
import Typography from "@mui/material/Typography";
import image1 from "../../components/imageGallery/images/1.jpg";
import Footer from "../../components/footer/footer";
import "./animationHome.scss"

function Home() {
    const imageRef = useRef(null);
    const { currentLang } = useContext(LanguageContext);

    useEffect(() => {
        const imageElement = imageRef.current;
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.001
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    imageElement.classList.add("scaleAnimation");
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(imageElement);

        return () => {
            observer.disconnect();
        };
    }, []);
  return (<>

    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={3}
      color="white"
      style={{ background: "#303841", paddingBottom: 50 }}
    >
      <Grid item xs={12}>
        <Navbar/>
      </Grid>
      <Grid item xs={11} sm={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={0}
          color="inherit"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"

              sx={{
                mr: 2,
                fontFamily: 'sans-serif',
                fontSize: 40,
                fontWeight: 600,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {toLocaleLanguage('FIRST_TITLE', currentLang)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                mr: 2,
                fontFamily: 'sans-serif',
                fontSize: 22,
                color: 'inherit',
                textDecoration: 'none',
                paddingTop: "16px"
              }}
            >
              {toLocaleLanguage('FIRST_TEXT', currentLang)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11} sm={5}>
        <ImageGallery/>
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={3}
      color="#3A4750"
      style={{ background: "white", paddingBottom: 30 }}
    >
      <Grid item xs={11} sm={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={0}
          color="inherit"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"

              sx={{
                mr: 2,
                fontFamily: 'sans-serif',
                fontWeight: 600,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {toLocaleLanguage('SECOND_TITLE', currentLang)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                mr: 2,
                fontFamily: 'sans-serif',
                color: 'inherit',
                fontSize: 18,
                textDecoration: 'none',
                  paddingTop: "16px"
              }}
            >
              {toLocaleLanguage('SECOND_TEXT', currentLang)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={3}
      color="#3A4750"
      style={{ background: "white", paddingTop: 15, paddingBottom: 50 }}
    >
      <Grid item xs={11} sm={5}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={0}
          color="inherit"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"

              sx={{
                mr: 2,
                fontFamily: 'sans-serif',
                fontWeight: 600,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {toLocaleLanguage('THIRD_TITLE', currentLang)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                mr: 2,
                fontFamily: 'sans-serif',
                color: 'inherit',
                fontSize: 18,
                textDecoration: 'none',
                  paddingTop: "16px"
              }}
            >
              {toLocaleLanguage('THIRD_TEXT', currentLang)}
              </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11} sm={5}>

          <img ref={imageRef} src={image1} alt="Image 1" style={{ width: "100%" }}/>

      </Grid>
    </Grid>
      <Footer/>

  </>);
}

export default Home;
