import "./view.scss";
import MainSidebar from "../../components/sidebar/mainSidebar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import OrgSidebar from "../../components/sidebar/orgSidebar";
import { translateGender } from "../../helper/gender-helper";

const ViewRacer = () => {
  let { id } = useParams();
  const [values, setValues] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchData = async () => {
    try {
      let racerGetResponse = await axios.get("/api/racer/get", {
        params: {
          id: id
        }
      });
      let raceGetResponse = await axios.get("/api/race/get", {
        params: {
          id: racerGetResponse.data.raceId
        }
      })
      let categoryGetResponse = await axios.get("/api/category/get", {
        params: {
          id: racerGetResponse.data.categoryId
        }
      })

      let defaultValues = {
        "Jméno": racerGetResponse.data.name,
        "Příjmení": racerGetResponse.data.surname,
        "Pohlaví": translateGender(racerGetResponse.data.gender),
        "Věk": racerGetResponse.data.age,
        "Rok narození": new Date(racerGetResponse.data.dateOfBirth).getFullYear(),
        "Národnost": racerGetResponse.data.nationality,
        "Startovací číslo": racerGetResponse.data.startNumber,
        "Email": racerGetResponse.data.email,
        "Telefoní číslo": racerGetResponse.data.phoneNumber,
        "Závod": raceGetResponse.data.name,
        "Kategorie": categoryGetResponse.data.name,
      };
      racerGetResponse.data.customRowsValues.forEach((row) => {
        defaultValues[row.name] = row.value
      })

      setValues(defaultValues);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const verifyAdmin = async () => {
    const { data } = await axios.post("/api/", {}, { withCredentials: true });
    if (data.status && data.type === "ADMIN") {
      setIsAdmin(true);
    }
  };

  useEffect(() => {
    verifyAdmin()
    fetchData();
  }, []);

  return <div className="view">
    {isAdmin ? <MainSidebar/> : <OrgSidebar/>}
    <div className="viewContainer" style={{paddingTop: "5vh"}}>
      <div className="wrapper">
        <div className="header">
          <h4>Detail závodníka</h4>
        </div>
        <div className="dataSection">
          {Object.entries(values).map(([key, value]) => (<div className="dataItem" key={key}>
            <label>{key}</label>
            <span>{value}</span>
          </div>))}
        </div>
      </div>
    </div>
  </div>

};

export default ViewRacer;
