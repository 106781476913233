import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid } from "@mui/material";
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Default width for both PC and mobile
  maxWidth: 450, // Maximum width for PC
  maxHeight: 600,
  bgcolor: 'WHITE',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  '@media (max-width: 600px)': { // Media query for mobile devices
    width: '90%' // Adjust width for mobile devices
  }
};


const DeleteModal = ({ text, opened, handleOpen, id, handleDelete }) => {

  const handleClose = () => {
    handleOpen(false);
  };

  const handleDeleteAction = () => {
    handleClose(); // Close the modal
    // Call the handleDelete function passed from RaceDatatableAdmin with the id
    handleDelete(id);
  };

  return (
    <div>
      <Modal
        open={opened}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            spacing={2}
            color="black"
          >
            <Grid item xs={11}>
              <p>{text}</p>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="success" onClick={handleDeleteAction}>
                ANO
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="outlined" color="error" onClick={handleClose}>
                NE
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
export default DeleteModal
