import React from 'react';
import { Grid } from '@mui/material';
import Navbar from '../../components/navbar/navbar';
import ResultsOnlineDatatable from "../../components/datatable/resultsOnlineDatatable";


function ResultsOnline({type}) {
  return (
    <div style={{background: "#303841", minHeight: "100vh"}}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={0}
        color="white"
      >
        <Grid item xs={12}>
          {type === "default" && <Navbar />}
        </Grid>
        <Grid item xs={12} md={12} style={{paddingTop: 20 }}>
          <ResultsOnlineDatatable type={type}/>
        </Grid>
      </Grid>
    </div>
  );

}

export default ResultsOnline;
