import "./featured.scss"
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Countdown from "react-countdown";

const Featured = ({ isRaceActive, data, percentage }) => {
  return (
    <div className="featured">
      {isRaceActive ? (
        <div className="top">
          <h1 className="title">NADCHÁZEJÍCÍ ZÁVOD</h1>
          <div></div>
        </div>
      ) : <div className="top">
            <h1 className="title">Žádný nadcházející závod</h1>
            <div></div>
        </div>}
      {isRaceActive && (
        <div className="bottom">
          <span className="title">{data.name}</span>
          <div className="featuredChart">
            <CircularProgressbar
              value={percentage}
              text={percentage+"%"}
              strokeWidth={5}
              styles={{
                path: { stroke: "#ff6000" },
                text: { fill: "#ff6000" }
              }}
            />
          </div>
          <p className="title">Zaplnění závodu</p>
          <Countdown date={new Date(data.start).getTime()}/>
          <p className="desc">Odpočet do Startu.</p>
        </div>
      )}
    </div>
  )
}

export default Featured