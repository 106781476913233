import "./org.scss";
import React, { useEffect, useState } from 'react';
import OrgSidebar from "../../components/sidebar/orgSidebar";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import NavBar from "../../components/navbar/navbar";
import Widget from "../../components/widget/Widget";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";
import Featured from "../../components/featured/featured";

const Organizator = () => {
  const raceId = sessionStorage.getItem('raceId');
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [statistics, setStatistics] = useState({});

  const loadStatistics = async () => {
    let loadStatisticsResponse = await axios.get("/api/statistics/get", {
      params: {
        raceId: raceId
      }
    });
    setStatistics(loadStatisticsResponse.data.statistics)
  }

  const verifyCookie = async () => {
    if (!cookies.token) {
      navigate("/login");
    } else {
      const { data } = await axios.post("/api/", {}, { withCredentials: true }
      );
      const { status } = data;
      if (status) {
        toast.success('Přihlášen jako Organizátor')
        return true
      } else {
        removeCookie("token");
        navigate("/admin");
        return false
      }
    }
  };

  useEffect(() => {
    loadStatistics();
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  return (
    <div className="home">
      <OrgSidebar/>
      <div className="homeContainer">
        <NavBar/>
        <div className="widgets" style={{width: "60%"}}>
          <Widget
            type="ZÁVODNÍCI"
            showAll="Zobrazit všechny závodníky"
            amount={statistics?.racers?.totalAmount || 0}
            shodAdd={true}
            showAllLink="/admin/organizator/racers"
            icon={<SportsMotorsportsIcon className="icon"/>}
            addLink={`/admin/racers/new/${raceId}`}
          />
        </div>
        <div className="charts">
          <Featured
            isRaceActive={statistics?.isRaceActive || false}
            data={statistics?.activeRaceData || null}
            percentage={statistics?.activeRacePercentage || 0}
          />
          <div style={{display: "flex", flex: 4}}></div>
        </div>
        <div>
          <Toaster
            position="top-right"
            reverseOrder={false}
          />
        </div>
      </div>
    </div>
  );
}
export default Organizator