import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from "react-router-dom";
import LanguageContext from "../translator/languageContext";
import { toLocaleLanguage } from "../translator/translation-helper";
import { csCZ, plPL } from "@mui/x-data-grid/locales";
import { useMediaQuery } from "@mui/material";

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
}

const ResultDatatableMain = () => {
  const [data, setData] = useState([]);
  const { currentLang } = useContext(LanguageContext);
  const isMobile = useMediaQuery('(max-width:900px)');

  const downloadPDF = async (filename) => {
    try {
      const response = await axios.get(`/api/files/${filename}`, {
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const fetchData = async () => {
    try {
      let response = await axios.get('/api/files');
      let data = response.data.itemList.map((item) => {
        let croppedId = item.filename.slice(0, -4);
        return {
          id: croppedId,
          name: croppedId,
        };
      });

      setData(data);
    } catch (error) {
      console.error('Error fetching PDFs:', error);
    }
  };
  const returnLanguage = () => {
    if (currentLang === "cz") {
      return csCZ.components.MuiDataGrid.defaultProps.localeText;
    } else if (currentLang === "pl") {
      return plPL.components.MuiDataGrid.defaultProps.localeText;
    } else {
      return ""
    }
  }

  useEffect(() => {
    fetchData()
  }, []);

  const actionColumn = [{
    field: "action", headerName: "", flex: 0.15, minWidth: 70, sortable: false, filterable: false, disableColumnMenu: true, renderCell: (params) => {
      const handleDownloadClick = (event) => {
        event.stopPropagation();
        downloadPDF(params.row.name + '.pdf');
      };

      return (<div>
        <DownloadIcon sx={{ fontSize: { xs: 16, md: 20 }, cursor: 'pointer', color: "#EEEEEE" }} onClick={handleDownloadClick}/>
        <Link to={`/pdf/${params.row.name}`} style={{ textDecoration: "none" }}>
          <VisibilityIcon
            sx={{ fontSize: { xs: 16, md: 20 }, marginLeft: 1.5, cursor: 'pointer', color: "#EEEEEE" }}
          /> </Link>

      </div>);
    },
  },];

  return (<>
    {data.length > 0 && (
      <div className="DataGrid" style={{ width: '100%' }}>
        <DataGrid
          rows={data}
          columns={[
            {
              field: "name",
              headerName: toLocaleLanguage("RESULTS_NAVBAR", currentLang),
              flex: 1,
              disableColumnMenu: isMobile,
              minWidth: 260,
              fontsize: 20,
            },
          ].concat(actionColumn)}
          localeText={returnLanguage()}
          sx={{
            boxShadow: 0,
            borderColor: '#303841',
            marginBottom: 3,
            minHeight: "83vh",
            backgroundColor: '#303841',
            '& .MuiDataGrid-withBorderColor': {
              borderColor: '#EEEEEE',
              color: '#EEEEEE',
              fontSize: { xs: 10, md: 14 },

            },
            '&   .MuiDataGrid-columnHeaderTitleContainer': {
              fontSize: { xs: 25, md: 30 },
            },
            '&   .MuiTablePagination-displayedRows': {
              marginBottom: "1em",
              marginTop: "1em",
              color: '#EEEEEE',
            },
            '&   .MuiSelect-icon ': {
              color: '#EEEEEE',
            },
            '&   .MuiSvgIcon-fontSizeSmall ': {
              color: '#EEEEEE',
            },
            '&  .MuiDataGrid-sortIcon': {
              color: '#EEEEEE',
            },
            '& .MuiTablePagination-root': {
              color: '#EEEEEE',
            },
            '& .MuiTablePagination-selectLabel': {
              marginBottom: "1em",
              marginTop: "1em",
              color: '#EEEEEE',
            }
          }}

          initialState={{
            pagination: { paginationModel: { pageSize: 100 } }
          }}
          pageSizeOptions={[5, 10, 25,50, 100]}
          slots={{ columnMenu: CustomColumnMenu }}
          disableColumnSorting

        />

      </div>
    )}
  </>);
};

export default ResultDatatableMain;