import React, { useContext, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import axios from "axios";
import { Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import LanguageContext from "../translator/languageContext";
import { toLocaleLanguage } from "../translator/translation-helper";

const RaceDatatableMainOverview = () => {
  const [data, setData] = useState([]);
  const { currentLang } = useContext(LanguageContext);

  function formatDate(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);
    const hour = dateString.substring(11, 13);
    const minute = dateString.substring(14, 16);

    return `${day}.${month}.${year} ${hour}:${minute}`;
  }

  const fetchData = async () => {
    try {
      const currentDate = new Date().toISOString();
      let raceListResponse = await axios.post("/api/race/list", {
        "filterList": [
          {
            "key": "start",
            "greaterThan": currentDate
          }
        ]
      });
      let raceIdList = raceListResponse.data.itemList.map(race => race._id)
      let racerListResponse = await axios.post("/api/racer/list", {
        "filterList": [
          {
            "key": "raceId",
            "valueList": raceIdList
          }
        ]
      })
      let data = raceListResponse.data.itemList.map((item) => {
        return {
          id: item._id,
          name: item.name,
          registrationEnd: formatDate(item.registrationEnd),
          start: formatDate(item.start),
          maxRacers: item.maxRacers,
          registeredRacers: racerListResponse.data.itemList.filter(racer => racer.raceId === item._id).length,
          remainingPlaces: item.maxRacers - racerListResponse.data.itemList.filter(racer => racer.raceId === item._id).length,
          closed: item.closed
        };
      })
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
      <>
        {data.length > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead >
                  <TableRow>
                    <TableCell align="center" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}>{toLocaleLanguage("RACE", currentLang)}</TableCell>
                    <TableCell align="center" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}>{toLocaleLanguage("DATE_OF_EVENT", currentLang)}</TableCell>
                    <TableCell align="center" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}>{toLocaleLanguage("END_OF_REGISTRATION", currentLang)}</TableCell>
                    <TableCell align="center" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}>{toLocaleLanguage("REGISTERED_RACERS", currentLang)}</TableCell>
                    <TableCell align="right" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >
                  {data.map((data) => (
                      <TableRow key={data.id}  sx={{ color: 'white', fontSize: '9px', width: '10px'  }}>
                        <TableCell align="center" component="th" scope="row" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}>
                          {data.name}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}>
                          {data.start}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}>
                          {data.registrationEnd}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row" sx={{ fontSize: { xs: '9px', md: '14px' }, color: 'white'}}>
                          {data.registeredRacers}
                        </TableCell>
                        <TableCell align="right" onClick={() => sessionStorage.setItem('raceIdOverview', data.id)} >
                          <Link to={`/racerDatatable`} style={{ textDecoration: "none" }}>
                            <VisibilityIcon sx={{ display: { xs: 'inline', md: 'none' }, color: 'white', fontSize: 18 }} />
                            <Button variant="contained" color="primary" sx={{
                              display: { xs: 'none', md: 'inline' },
                              fontSize: 13,
                            }}>{toLocaleLanguage("RACERS", currentLang)}</Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        ) : toLocaleLanguage("NO_RACE_IN_SYSTEM", currentLang)}
      </>
  );
};

export default RaceDatatableMainOverview;

