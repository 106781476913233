import "./update.scss";
import MainSidebar from "../../components/sidebar/mainSidebar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate, useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import NumberGridModal from "../../components/modal/numberGrid";
import { translateGender } from "../../helper/gender-helper";
import OrgSidebar from "../../components/sidebar/orgSidebar";

const UpdateRacer = ({ inputs }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ paid: false });
  const [showPositiveAlert, setShowPositiveAlert] = useState(false);
  const [showNegativeAlert, setShowNegativeAlert] = useState(false);
  const [errorMessageParams, setErrorMessageParams] = useState([]);
  const [createdRacerName, setCreatedRacerName] = useState("");
  const [customRows, setCustomRows] = useState([]);
  const [customRowsContextValues, setCustomRowsContextValues] = useState({});
  const [modalOpened, setModalOpened] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [multipleCategories, setMultipleCategories] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(undefined);
  const [selectedCategoryName, setSelectedCategoryName] = useState(undefined);
  const [selectedStartNumber, setSelectedStartNumber] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleInputChange = async (e, id) => {
    switch (id) {
      case "paid":
        setFormData({
          ...formData,
          [id]: e.target.checked
        });
        break;
      case "gender": {
        setFormData({
          ...formData,
          [id]: e.target.value
        });
        const gender = e.target.value;
        if ((gender === "Muž" || gender === "Žena") && formData["dateOfBirth"] && formData["dateOfBirth"] !== null) {
          await assignCategory(e.target.value, formData["dateOfBirth"]);
        } else {
          setSelectedCategoryId(undefined)
          setSelectedCategoryName(undefined)
          setSelectedStartNumber(null)
        }
        break;
      }
      case "dateOfBirth": {
        setFormData({
          ...formData,
          [id]: e.target.value
        });
        const genderValues = ["Muž", "Žena"];
        if (genderValues.includes(formData["gender"]) && e.target.value) {
          await assignCategory(formData["gender"], e.target.value);
        } else {
          setSelectedCategoryId(undefined)
          setSelectedCategoryName(undefined)
          setSelectedStartNumber(null)
        }
        break;
      }
      default: {
        setFormData({
          ...formData,
          [id]: e.target.value
        });
        break;
      }
    }
  };

  const assignCategory = async (gender, dateOfBirth, raceId = null) => {
    let assignCategoryResponse = await axios.post("/api/race/assignCategoryToRacer", {
      raceId: raceId === null ? formData.raceId : raceId,
      gender: translateGender(gender),
      dateOfBirth: dateOfBirth
    })

    if (assignCategoryResponse?.data.categoryList && assignCategoryResponse.data.categoryList.length > 0) {
      setCategoryList(assignCategoryResponse.data.categoryList)
      setSelectedStartNumber(null)
      setSelectedCategoryId(undefined)
      setMultipleCategories(true)
    } else if (assignCategoryResponse?.data.category) {
      setSelectedCategoryId(assignCategoryResponse.data.category._id)
      setSelectedCategoryName(assignCategoryResponse.data.category.name)
      setSelectedStartNumber(null)
      setCategoryList([])
      setMultipleCategories(false)
    } else {
      setSelectedCategoryName(assignCategoryResponse.data.message === "CANNOT_BE_CLASSIFIED" && "Nelze klasifikovat.");
      setSelectedCategoryId(undefined)
      setCategoryList([])
      setMultipleCategories(false)
      setSelectedStartNumber(null)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleUpdate();
  };

  const assembleCustomRowsValues = () => {
    let customRowsValues = [];
    Object.entries(customRowsContextValues).forEach(([key, value]) => {
      customRowsValues.push({ name: key, value: value });
    });
    return customRowsValues;
  };

  const handleUpdate = async () => {
    try {
      let createRacerData = {
        id: id,
        name: formData.name,
        surname: formData.surname,
        gender: translateGender(formData.gender),
        dateOfBirth: formData.dateOfBirth,
        nationality: formData.nationality,
        categoryId: selectedCategoryId,
        startNumber: selectedStartNumber,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        raceId: formData.raceId,
        customRows: assembleCustomRowsValues(),
        paid: formData.paid
      }
      console.log(createRacerData)
      let racerCreateResponse = await axios.post("/api/racer/update", createRacerData);
      setShowPositiveAlert(true);
      setCreatedRacerName(racerCreateResponse.data.updatedRacer.name);
      setFormData({});
      setSelectedCategoryName(undefined)
      setSelectedStartNumber(null)
      setSelectedCategoryId(undefined)
      setCustomRowsContextValues({})
      const response = await axios.post('/api/', {}, { withCredentials: true });
      const { type } = response.data;
      setTimeout(() => {
        setShowPositiveAlert(false);
        if (type === "ADMIN") {
          navigate("/admin/racers")
        } else {
          navigate("/admin/organizator")
        }
      }, 2000);
      await loadData();
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error(error.response.data);
      setErrorMessageParams(Object.values(error.response.data.paramMap));
      setShowNegativeAlert(true);
    }
  };

  const handleChangeCustomRowValues = (e, id) => {
    setCustomRowsContextValues({
      ...customRowsContextValues,
      [id]: e.target.value
    });
  }

  const loadData = async () => {
    try {
      let racerGetResponse = await axios.get("/api/racer/get", {
        params: {
          id: id
        }
      })
      let racer = racerGetResponse.data
      setFormData({
        ...formData,
        raceId: racer.raceId,
        name: racer.name,
        surname: racer.surname,
        gender: translateGender(racer.gender),
        dateOfBirth: new Date(racer.dateOfBirth).toISOString().split('T')[0],
        nationality: racer.nationality,
        email: racer.email,
        phoneNumber: racer.phoneNumber,
        paid: racer.paid,
        categoryId: racer.categoryId
      })
      await assignCategory(translateGender(racer.gender), new Date(racer.dateOfBirth), racer.raceId)
      let categoryID = (await axios.get("/api/category/get?id=" + racer.categoryId)).data._id
      setSelectedCategoryId(categoryID)
      setSelectedStartNumber(racer.startNumber)

    } catch (error) {
      console.error("Error fetching data:", error);

    }
  }

  const verifyAdmin = async () => {
    const { data } = await axios.post("/api/", {}, { withCredentials: true });
    if (data.status && data.type === "ADMIN") {
      setIsAdmin(true);
    }
  };

  useEffect(() => {
    verifyAdmin()
    loadData()
  }, []);

  return (
    <div className="update">
      {isAdmin ? <MainSidebar/> : <OrgSidebar/>}
      <div className="updateFormContainer" style={{ paddingTop: "2vh" }}>
        <div className="wrapper">
          <div className="header">
            <h4>{"Upravit závodníka"}</h4>
          </div>
          <div className="dataSection" style={{ borderBottom: "none" }}>
            <div className="right">
              <h2>Základní informace</h2>
              <form onSubmit={handleSubmit}>
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    {input.type === "select" ? (
                      <FormControl variant="standard" style={{ width: '100%', border: "0.5px solid rgb(230, 227, 227)", borderRadius: "4px" }}>
                        <Select
                          disableUnderline={true}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          onChange={(e) => handleInputChange(e, input.id)}
                          style={{ textAlign: "center" }}
                          value={formData[input.id] || ""}
                          sx={{
                            backgroundColor: "#f5f5f5",
                            border: "0.5px solid rgb(180, 178, 178)"
                          }}
                        >
                          {input.options.map((option, index) => (
                            <MenuItem key={index} value={option}>{option}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : input.type === "checkbox" ? (
                      <FormGroup>
                        <FormControlLabel
                          checked={formData[input.id] || false}
                          control={<Checkbox onChange={(e) => handleInputChange(e, input.id)} style={{ color: "#FF6000" }}/>}
                        />
                      </FormGroup>
                    ) : (
                      <input
                        type={input.type}
                        placeholder={input.placeholder}
                        onChange={(e) => handleInputChange(e, input.id)}
                        value={formData[input.id] || ""}
                        {...(input.id === "dateOfBirth" && {
                          min: "yyyy-mm-dd",
                          max: "yyyy-mm-dd",
                          step: "1",
                        })}
                      />
                    )}
                  </div>
                ))}
                {multipleCategories === false ? (
                  <div className="formText">
                    <label>Kategorie</label>
                    <span style={{ cursor: "auto", backgroundColor: "#f5f5f5", border: "0.5px solid rgb(180, 178, 178)" }}>
                     {selectedCategoryName || "Nepřidělena"}
                    </span>
                  </div>
                ) : (
                  <div className="formInput">
                    <label>Kategorie</label>
                    <FormControl
                      variant="standard"
                      style={{
                        width: '100%',
                        border: "0.5px solid rgb(230, 227, 227)",
                        borderRadius: "4px"
                      }}
                    >
                      <Select
                        disableUnderline={true}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        style={{ textAlign: "center" }}
                        onChange={(e) => {
                          setSelectedCategoryId(e.target.value);
                          setSelectedStartNumber(null);
                        }}
                        value={selectedCategoryId || "Placeholder"}
                        label="Age"
                        sx={{
                          backgroundColor: "#f5f5f5",
                          border: "0.5px solid rgb(180, 178, 178)"
                        }}
                      >
                        <MenuItem disabled value="Placeholder">
                          Nevybráno
                        </MenuItem>
                        {categoryList.map((category, index) => (
                          <MenuItem key={index} value={category._id}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                <div className="formText">
                  <label>Startovací číslo</label>
                  <span onClick={() => setModalOpened(true)} style={{ backgroundColor: "#f5f5f5", border: "0.5px solid rgb(180, 178, 178)" }}>
                    {selectedStartNumber || "Nevybráno"}
                  </span>
                </div>
                {
                  customRows.length <= 0 && <Button type="submit">Upravit</Button>
                }
              </form>
            </div>
          </div>
          {customRows.length > 0 &&
            <>
              <div className="dataSection" style={{ borderBottom: "none" }}>
                <div className="right">
                  <h2>Vlastní pole</h2>
                  <form onSubmit={handleSubmit}>
                    {customRows.map((customRow, index) => (
                      <div className="formInput" key={index + "label"} style={{ width: "45%" }}>
                        <label>{customRow.label}</label>
                        <input
                          value={customRowsContextValues[customRow.id] || ""}
                          type="text"
                          placeholder={customRow?.placeholder ?? ""}
                          onChange={(e) => handleChangeCustomRowValues(e, customRow.id)}
                        />
                      </div>
                    ))}
                  </form>
                </div>
              </div>
              <div className="dataSection">
                <div className="right">
                  <form onSubmit={handleSubmit}>
                    <Button type="submit">Upravit</Button>
                  </form>
                </div>
              </div>
            </>
          }
          <NumberGridModal
            categoryId={selectedCategoryId}
            raceId={formData.raceId}
            opened={modalOpened}
            handleOpen={(open) => setModalOpened(open)}
            selectedNumber={(number) => setSelectedStartNumber(number)}
          />
          {showPositiveAlert && (
            <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success">
              Závodník {createdRacerName} úspěšně upraven!
            </Alert>
          )}

          {showNegativeAlert && (
            <Alert severity="error" onClose={() => {
              setShowNegativeAlert(false)
            }}>
              <AlertTitle>Závodníka se se nepodařilo vytvořit.</AlertTitle>
              {errorMessageParams.map((param, index) => (
                <p style={{ margin: 0, paddingTop: 2 }} key={index}>{param}</p>
              ))}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateRacer;
