import { DataGrid, GridColumnMenu } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Button, useMediaQuery } from "@mui/material";
import ContactModal from "../modal/contactModal";
import { Toaster } from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import LanguageContext from "../translator/languageContext";
import { toLocaleLanguage } from "../translator/translation-helper";
import { csCZ,plPL } from '@mui/x-data-grid/locales';

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
}

const RacerDatatableMain = () => {
  const raceId = sessionStorage.getItem('raceIdOverview');
  const [data, setData] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [passedName, setPassedName] = useState("");
  const [passedSurname, setPassedSurname] = useState("");
  const [passedStartNumber, setPassedStartNumber] = useState("");
  const [passedRaceName, setPassedRaceName] = useState("");
  const { currentLang } = useContext(LanguageContext);

  const isMobile = useMediaQuery('(max-width:900px)');


  const fetchData = async () => {
    try {
      let racerListResponse = await axios.post("/api/racer/list", {
        "filterList": [
          {
            "key": "raceId",
            "value": raceId
          }
        ]
      });
      let categoryIdList = racerListResponse.data.itemList.map(racer => {
        return racer.categoryId
      });
      let raceIdsList = racerListResponse.data.itemList.map(racer => {
        return racer.raceId
      }).filter((value, index, self) => self.indexOf(value) === index);
      let categoryListInput = {
        filterList: [
          { key: "id", valueList: categoryIdList }
        ]
      }
      let raceListInput = {
        filterList: [
          { key: "id", valueList: raceIdsList }
        ]
      }
      let raceList = (await axios.post("/api/race/list", raceListInput)).data.itemList;
      let categoryList = (await axios.post("/api/category/list", categoryListInput)).data.itemList;
      let data = await Promise.all(
        racerListResponse.data.itemList.map(async (item) => {
          return {
            id: item._id,
            startNumber: item.startNumber,
            name: item.name + "  " + item.surname,
            category: categoryList.find(category => category._id === item.categoryId).name,
            raceName: raceList.find(race => race._id === item.raceId).name,
          };
        })
      );
      setData(data);
    } catch (error) {
      console.error('Error fetching PDFs:', error);
    }
  };

  useEffect(() => {
    if (raceId) {
      fetchData();
    }
  }, []);

  const handleOpenModal = (data) => {
    setPassedName(data.name);
    setPassedSurname(data.surname);
    setPassedStartNumber(data.startNumber);
    setPassedRaceName(data.raceName);
    setModalOpened(true);
  }

  const returnLanguage = () => {
    if (currentLang === "cz") {
      return csCZ.components.MuiDataGrid.defaultProps.localeText;
    } else if (currentLang === "pl") {
      return plPL.components.MuiDataGrid.defaultProps.localeText;
    } else {
      return ""
    }
  }

  const actionColumnMobile = [{
    field: "action",
    headerName: "",
    minWidth: 30,
    flex: 0.1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    align: "right",
    renderCell: (params) => {
      return (
        <div>
          <EditIcon color="primary" sx={{ display: 'inline', fontSize: 12, color: "white" }} onClick={() => handleOpenModal(params.row)}/>
        </div>
      );
    },
  }];

  const actionColumnPC = [{
    field: "action",
    headerName: "",
    width: 165,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    align: "right",
    renderCell: (params) => {
      return (
        <div>
          <Button variant="contained" color="primary" sx={{ fontSize: 11, color: "white" }} onClick={() => handleOpenModal(params.row)}>{toLocaleLanguage("CHANGE_REQUEST", currentLang)}</Button>
        </div>
      );
    },
  }];

  const actionColumn = isMobile ? actionColumnMobile : actionColumnPC;

  return (<>
    {data.length > 0 ? (
      <div className="DataGrid" style={{ width: '100%' }}>
        <DataGrid
          rows={data}
          columns={[
              {
                field: "name",
                headerName: toLocaleLanguage("NAME_AND_SURNAME", currentLang),
                flex: 0.9,
                sortable: !isMobile,
                minWidth: 80,
              },
          {
            field: "startNumber",
            headerName: toLocaleLanguage("NUMBER", currentLang),
            sortable: !isMobile,
            flex: 0.3,
            minWidth: 60,
          },
          {
            field: "category",
            headerName: toLocaleLanguage("CATEGORY", currentLang),
            sortable: !isMobile,
            flex: 0.8,
            minWidth: 50,
          },
            ].concat(actionColumn)}
          localeText={returnLanguage()}
          disableColumnSorting
          sx={{
            boxShadow: 0,
            borderColor: '#303841',
            marginBottom: 3,
            textAlign: 'center',
            backgroundColor: '#303841',
            fontSize: { xs: "10px", md: "14px" },
            width: '100%',
            minHeight: '75vh',
            align: "center",
            '& .MuiDataGrid-withBorderColor': {
              borderColor: '#EEEEEE',
              color: '#EEEEEE',
              fontSize: { xs: 10, md: 14 },

            },
            '&   .MuiTablePagination-displayedRows': {
              marginBottom: "1em",
              marginTop: "1em",
              color: '#EEEEEE',
            },
            '&   .MuiSelect-icon ': {
              color: '#EEEEEE',
            },
            '&   .MuiSvgIcon-fontSizeSmall ': {
              color: '#EEEEEE',
            },
            '&  .MuiDataGrid-sortIcon': {
              color: '#EEEEEE',
            },
            '& .MuiTablePagination-root': {
              color: '#EEEEEE',
            },
            '& .MuiTablePagination-selectLabel': {
              marginBottom: "1em",
              marginTop: "1em",
              color: '#EEEEEE',
            }

          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
            sorting: { sortModel: [{ field: "startNumber", sort: "asc" }] },
          }}
          pageSizeOptions={[5, 10, 25,50, 100]}
          slots={{ columnMenu: CustomColumnMenu }}
          disableColumnMenu={isMobile}
        />
        <div>
          <Toaster
            position="top-right"
            reverseOrder={false}
          />
        </div>
        <ContactModal
          racerName={passedName}
          racerSurname={passedSurname}
          startNumber={passedStartNumber}
          raceName={passedRaceName}
          handleOpen={(open) => setModalOpened(open)}
          opened={modalOpened}
        />

      </div>
    ) : <div style={{paddingTop: "20px", justifyContent: "center", textAlign: "center"}}>{toLocaleLanguage("NO_RACERS_IN_RACE", currentLang)}</div>}
  </>);
};

export default RacerDatatableMain;