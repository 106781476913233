import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { Grid } from "@mui/material";
import LanguageIcon from '@mui/icons-material/Language';
import LanguageContext from "../translator/languageContext";
import { toLocaleLanguage } from "../translator/translation-helper";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElLan, setAnchorElLan] = React.useState(null);
  const { currentLang, changeLanguage } = React.useContext(LanguageContext);

  const pages = [
    { label: toLocaleLanguage("RESULTS_NAVBAR", currentLang), route: '/result' },
    { label: toLocaleLanguage('REGISTRATION', currentLang), route: '/registration' },
    { label: toLocaleLanguage('OVERVIEW_NAVBAR', currentLang), route: '/overview' },
    { label: toLocaleLanguage('LIVE_RESULTS_NAVBAR', currentLang), route: '/resultsOnline' }
  ];
  const languages = [
    { label: 'CZ', langCode: 'cz' },
    { label: 'EN', langCode: 'en' },
    { label: 'PL', langCode: 'pl' },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLanguageMenu = (event) => {
    setAnchorElLan(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLan(null);
  };

  const handleLanguageClick = (langCode) => {
    changeLanguage(langCode);
    handleCloseLanguageMenu();
  };

  return (
    <AppBar position="static" style={{ background: "#3A4750" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={0}
            color="white"
            style={{ background: "#3A4750" }}
          >
            <Grid item xs={1} md={1.8} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h6"
                component={Link}
                to="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'sans-serif',
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                Casomira.eu
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon/>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map(({ label, route }) => (
                    <MenuItem key={label} onClick={handleCloseNavMenu} component={Link} to={route}>
                      <Typography textAlign="center" sx={{
                        textDecoration: 'none',
                        color: 'black',
                        my: 2,
                        display: 'block'
                      }}>{label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

            </Grid>
            <Grid item xs={7} md={9}>

              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "center" }}>
                {pages.map(({ label, route }) => (
                  <Button
                    key={label}
                    component={Link}
                    to={route}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: 'inherit', display: 'block', paddingRight: "15px", paddingLeft: "15px" }}
                  >
                    {label}
                  </Button>
                ))}
              </Box>
              <Typography
                variant="h5"
                noWrap
                component={Link}
                to="/"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'sans-serif',
                  fontWeight: 700,
                  color: 'inherit',
                  textDecoration: 'none',
                  justifyContent: "center",
                }}
              >
                Casomira.eu
              </Typography>
            </Grid>
            <Grid item xs={1} md={1.2} sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ flexGrow: 0 }}>
                <IconButton onClick={handleOpenLanguageMenu} sx={{ p: 0 }}>
                  <LanguageIcon sx={{ color: "white" }}/>
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElLan}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElLan)}
                  onClose={handleCloseLanguageMenu}
                >
                  {languages.map(({ label, langCode }) => (
                    <MenuItem key={label} onClick={() => handleLanguageClick(langCode)}>
                      <Typography textAlign="center">{label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;

