import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import Navbar from '../../components/navbar/navbar';
import RaceDatatableMainOverview from "../../components/datatable/raceDatatableMainOverview";
import LanguageContext from "../../components/translator/languageContext";
import { toLocaleLanguage } from "../../components/translator/translation-helper";

function Overview() {
  const { currentLang } = useContext(LanguageContext);

  return (
    <div style={{ background: "#303841", minHeight: "100vh" }}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={0}
        color="white"
      >
        <Grid item xs={12}>
          <Navbar/>
        </Grid>
          <Grid item xs={11} md={8} sx={{ paddingTop: {xs: 3, md: 4}, paddingLeft: 1,  fontSize:{xs: "23px", md: "35px"}}}>
            {toLocaleLanguage("RACE_OVERVIEW", currentLang)}
          </Grid>
          <Grid item xs={11} md={8} sx={{ paddingTop:{xs: 1, md: 4}, paddingLeft: 1 }}>
          <RaceDatatableMainOverview />
        </Grid>
      </Grid>
    </div>
  );
}

export default Overview;

