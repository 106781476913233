import "./styles/sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShutterSpeedIcon from "@mui/icons-material/ShutterSpeed";
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from "react-router-dom";
import React from 'react'
import { useCookies } from "react-cookie";

const OrgSidebar = () => {
  const navigate = useNavigate();
  const raceId = sessionStorage.getItem('raceId');
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const logout = () => {
    removeCookie("token", { path: "/" });
    navigate("/admin");
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/admin" style={{ textDecoration: "none", paddingTop: "10px" }}>
          <span className="logo">Casomira.eu</span>
        </Link>
      </div>
      <hr/>
      <div className="center">
        <ul>
          <p className="title">HLAVNÍ</p>
          <Link to="/admin/organizator" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon"/>
              <span>Statistika</span>
            </li>
          </Link>
          <p className="title">PŘEHLED</p>
          <Link to={`/admin/races/update/${raceId}`} style={{ textDecoration: "none" }}>
            <li>
              <ShutterSpeedIcon className="icon"/>
              <span>Upravit závod</span>
            </li>
          </Link>
          <Link to="/admin/organizator/racers" style={{ textDecoration: "none" }}>
            <li>
              <SportsMotorsportsIcon className="icon"/>
              <span>Závodníci</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className="bottom" onClick={logout}>
        <li>
          <LogoutIcon className="icon"/>
          <span>Odhlásit</span>
        </li>
      </div>
    </div>

  );
};

export default OrgSidebar;