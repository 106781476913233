import React from 'react';
import './widget.scss';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link, useNavigate } from "react-router-dom";

const Widget = ({ type, showAll, amount, shodAdd, addLink, showAllLink, icon }) => {
  const navigate = useNavigate();
  return (
    <div className="widget">
      <div className="left">
        <span className="title">{type}</span>
        <span className="counter">{amount}</span>
        <span className="link" onClick={() => navigate(showAllLink)}>{showAll}</span>
      </div>
      <div className="right">
        {shodAdd ? <Link to={addLink}><AddCircleOutlineIcon className="addIcon"/></Link> : <div/>}
        {icon}
      </div>
    </div>
  )
}

export default Widget