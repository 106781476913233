import "./styles/datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumnsRacers } from "../../data/datatable-source";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import DeleteModal from "../modal/deleteModal";
import { csCZ } from "@mui/x-data-grid/locales";


const RacerDatatableOrganizator = () => {
  const [data, setData] = useState([]);
  const [multipleRowsSelected, setMultipleRowsSelected] = useState(false);
  const [checkBoxParams, setCheckBoxParams] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [passedText, setPassedText] = useState("");
  const [deleteMany, setDeleteMany] = useState(false);
  const [raceToDeleteId, setRaceToDeleteId] = useState("");
  const handleCheckboxSelection = (params) => {
    params.length > 0 ? setMultipleRowsSelected(true) : setMultipleRowsSelected(false);
    setCheckBoxParams(params);
  }

  const handleDeleteMany = async () => {
    for (let id of checkBoxParams) {
      await axios.get("/api/racer/delete", {
        params: {
          id: id
        }
      })
    }
    await fetchData();
  }

  const handleDelete = async (id) => {
    await axios.get("/api/racer/delete", {
      params: {
        id: id
      }
    })
    setData(data.filter((item) => item.id !== id));
  };

  const fetchData = async () => {
    try {
      let raceId = sessionStorage.getItem("raceId")
      let racerListResponse = await axios.post("/api/racer/list", {
        filterList: [
          { key: "raceId", value: raceId }
        ]
      });
      let categoryIdList = racerListResponse.data.itemList.map(racer => {return racer.categoryId});
      let raceIdsList = racerListResponse.data.itemList.map(racer => {return racer.raceId}).filter((value, index, self) => self.indexOf(value) === index);
      let categoryListInput = {
        filterList: [
          { key: "id", valueList: categoryIdList }
        ]
      }
      let raceListInput = {
        filterList: [
          { key: "id", valueList: raceIdsList }
        ]
      }
      let raceList = (await axios.post("/api/race/list", raceListInput)).data.itemList;
      let categoryList = (await axios.post("/api/category/list", categoryListInput)).data.itemList;
      let data = await Promise.all(
        racerListResponse.data.itemList.map(async (item) => {
          return {
            id: item._id,
            startNumber: item.startNumber,
            fullName: item.name + " " + item.surname,
            category: categoryList.find(category => category._id === item.categoryId).name,
            raceName: raceList.find(race => race._id === item.raceId).name,
          };
        })
      );
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {

    fetchData();
  }, []);
  const handleOpenModal = (text, id, many, event) => {
    event.stopPropagation(); // Stop event propagation
    setPassedText(text);
    setRaceToDeleteId(id);
    setModalOpened(true);
    setDeleteMany(many);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Akce",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/admin/racers/view/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">Zobrazit</div>
            </Link>
            <Link to={`/admin/racers/update/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="editButton">Upravit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={(event) => handleOpenModal("Opravdu chcete smazat závodníka: " + params.row.fullName + " ?", params.row.id, false, event)}
            >
              Smazat
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">
        Seznam závodníků.
        <div className="links">
          {multipleRowsSelected && (
            <Button variant="outlined" className="linkDeleteMany" onClick={(event) => handleOpenModal("Opravdu chcete smazat označené závodníky?", null, true, event)}>
              Smazat označené
            </Button>
          )}
        </div>
      </div>
      <DataGrid
        rows={data}
        columns={userColumnsRacers.concat(actionColumn)}
        checkboxSelection
        onRowSelectionModelChange={handleCheckboxSelection}
        localeText={csCZ.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
      <DeleteModal
        text={passedText}
        handleOpen={(open) => setModalOpened(open)}
        opened={modalOpened}
        id={raceToDeleteId}
        handleDelete={deleteMany ? handleDeleteMany : handleDelete}
      />
    </div>
  );
};

export default RacerDatatableOrganizator;