import React from 'react';
import './list.scss';
import MainSidebar from "../../components/sidebar/mainSidebar";
import NavBar from "../../components/navbar/navbar";
import RaceDatatableAdmin from "../../components/datatable/raceDatatableAdmin";

const ListRaces = () => {
  return (
    <div className="list">
      <MainSidebar/>
      <div className="listContainer">
        <NavBar/>
        <RaceDatatableAdmin/>
      </div>
    </div>
  );
}
export default ListRaces