import React from 'react';
import './list.scss';
import MainSidebar from "../../components/sidebar/mainSidebar";
import NavBar from "../../components/navbar/navbar";
import RacerDatatableAdmin from "../../components/datatable/racerDatatableAdmin";

const ListRacers = () => {
  return (
    <div className="list">
      <MainSidebar/>
      <div className="listContainer">
        <NavBar/>
        <RacerDatatableAdmin/>
      </div>
    </div>
  );
}
export default ListRacers