import React, { useEffect, useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import axios from "axios";
import { useParams } from "react-router-dom";

const PdfViewerMain = () => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { filename } = useParams();
    const [viewPdf, setViewPdf] = useState(null);

    useEffect(() => {
        const openPdf = async (filename) => {
            try {
                const response = await axios.get(`/api/open/${filename}`, {
                    responseType: 'blob' // Set response type to blob
                });
                setViewPdf(URL.createObjectURL(response.data));
            } catch (error) {
                console.error('Error opening PDF:', error);
            }
        };

        if (filename) {
            openPdf(filename + '.pdf');
        }
    }, [filename]);

    return (
        <div className='container'>
            <div className='pdf-container'>
                {viewPdf && (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        <Viewer fileUrl={viewPdf} plugins={[defaultLayoutPluginInstance]} />
                    </Worker>
                )}
                {!viewPdf && <div>No PDF file selected</div>}
            </div>
        </div>
    );
};

export default PdfViewerMain;
