import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeAdmin from "./adminpanel/pages/home/home";
import LoginAdmin from "./adminpanel/pages/login/login";
import ListRacersAdmin from "./adminpanel/pages/list/listRacers";
import ListRacesAdmin from "./adminpanel/pages/list/listRaces";
import ListCategoriesAdmin from "./adminpanel/pages/list/listCategories";
import ListResultsAdmin from "./adminpanel/pages/list/listResults";
import UpdateCategoryAdmin from "./adminpanel/pages/update/updateCategory";
import UpdateRacerAdmin from "./adminpanel/pages/update/updateRacer";
import UpdateRaceAdmin from "./adminpanel/pages/update/updateRace";
import ViewRacerAdmin from "./adminpanel/pages/view/viewRacer";
import ViewRaceAdmin from "./adminpanel/pages/view/viewRace";
import NewRacerAdmin from "./adminpanel/pages/new/newRacer";
import NewRaceAdmin from "./adminpanel/pages/new/newRace";
import NewCategoryAdmin from "./adminpanel/pages/new/newCategory";
import PdfViewerAdmin from "./adminpanel/pages/pdfViewer/pdfViewerAdmin";
import Home from "./main/pages/home/home";
import Results from "./main/pages/results/results";
import Overview from "./main/pages/overview/overview";
import Registration from "./main/pages/registration/registration";
import RegistrationForm from "./main/pages/registrationForm/registrationForm";
import OverviewForm from "./main/pages/overviewForm/overviewForm";
import PdfViewerMain from "./main/pages/pdfViewer/pdfViewerMain";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {racerInputsAdmin, raceInputs, categoryInputs, racerInputsMain} from "./adminpanel/data/form-source";
import RequireAdminAuth from "./adminpanel/components/RequireAdminAuth";
import Organizator from "./adminpanel/pages/organizator/organizator";
import RequireOrganizatorAuth from "./adminpanel/components/RequireOrganizatorAuth";
import ListRacersOrganizator from "./adminpanel/pages/list/listRacersOrganizator";
import ResultsOnline from "./main/pages/resultsOnline/resultsOnline";
import ViewCategoryLinkedRaces from "./adminpanel/pages/view/viewCategoryLinkedRaces";

function App() {

  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<Home/>}/>
            <Route path="result" element={<Results />}/>
            <Route path="registration" element={<Registration/>}/>
            <Route path="registrationForm" element={<RegistrationForm inputs={racerInputsMain}/>}/>
            <Route path="overview" element={<Overview />}/>
            <Route path="racerDatatable" element={<OverviewForm/>}/>
            <Route path="resultsOnline" element={<ResultsOnline type="default"/>}/>
            <Route path="brezolupy">
              <Route path="resultsOnline" element={<ResultsOnline type="brezolupy"/>}/>
            </Route>
            <Route path="battimx">
              <Route path="resultsOnline" element={<ResultsOnline type="battimx"/>}/>
            </Route>
            <Route path="pdf/:filename" element={<PdfViewerMain/>}/>
            <Route path="admin">
              <Route index element={<LoginAdmin/>}/>
              <Route path="home" element={<RequireAdminAuth children={<HomeAdmin/>}/>}/>
              <Route path="organizator">
                <Route index element={<RequireOrganizatorAuth children={<Organizator/>}/>}/>
                <Route path="racers">
                  <Route index element={<RequireOrganizatorAuth children={<ListRacersOrganizator/>}/>}/>
                </Route>
              </Route>
              <Route path="organizator" element={<RequireOrganizatorAuth children={<Organizator/>}/>}/>
              <Route path="racers">
                <Route index element={<RequireAdminAuth children={<ListRacersAdmin/>}/>}/>
                <Route path="new/:id" element={<RequireOrganizatorAuth children={<NewRacerAdmin inputs={racerInputsAdmin} title="Add New Racer"/>}/>}/>
                <Route path="update/:id" element={<RequireOrganizatorAuth children={<UpdateRacerAdmin inputs={racerInputsAdmin}/>}/>}/>
                <Route path="view/:id" element={<RequireOrganizatorAuth children={<ViewRacerAdmin inputs={racerInputsAdmin}/>}/>}/>
              </Route>
              <Route path="races">
                <Route index element={<RequireAdminAuth children={<ListRacesAdmin/>}/>}/>
                <Route path="new" element={<RequireAdminAuth children={<NewRaceAdmin inputs={raceInputs} title="Add New Race"/>}/>}/>
                <Route path="update/:id" element={<RequireOrganizatorAuth children={<UpdateRaceAdmin inputs={raceInputs}/>}/>}/>
                <Route path="view/:id" element={<RequireAdminAuth children={<ViewRaceAdmin inputs={raceInputs}/>}/>}/>
              </Route>
              <Route path="categories">
                <Route index element={<RequireAdminAuth children={<ListCategoriesAdmin/>}/>}/>
                <Route path="new" element={<RequireAdminAuth children={<NewCategoryAdmin inputs={categoryInputs} title="Add New Category"/>}/>}/>
                <Route path="update/:id" element={<RequireAdminAuth children={<UpdateCategoryAdmin inputs={categoryInputs}/>}/>}/>
                <Route path="linkedRaces/:id" element={<RequireAdminAuth children={<ViewCategoryLinkedRaces/>}/>}/>
              </Route>
              <Route path="results">
                <Route index element={<RequireAdminAuth children={<ListResultsAdmin/>}/>}/>
                <Route path="pdf/:filename" element={<RequireAdminAuth children={<PdfViewerAdmin/>}/>}/>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;

