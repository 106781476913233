export const translateGender = (gender) => {
  switch (gender) {
    case "MALE":
      return "Muž";
    case "FEMALE":
      return "Žena";
    case "Muž":
      return "MALE";
    case "Žena":
      return "FEMALE";
    case "Unisex":
      return "UNISEX";
    case "UNISEX":
      return "Unisex"
  }
}