import "./new.scss";
import MainSidebar from "../../components/sidebar/mainSidebar";
import React, { useState } from "react";
import axios from "axios";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { translateGender } from "../../helper/gender-helper";
import { Button } from "@mui/material";

const NewCategory = ({ inputs }) => {
  const [formData, setFormData] = useState({});
  const [showPositiveAlert, setShowPositiveAlert] = useState(false);
  const [showNegativeAlert, setShowNegativeAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [createdCategoryName, setCreatedCategoryName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = async (e, id) => {
    setFormData({
      ...formData,
      [id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleCreate();
    setFormData({})
  };

  const handleCreate = async () => {
    try {
      setLoading(true)
      let categoryCreateResponse = await axios.post("/api/category/create", {
        name: formData.name,
        gender: translateGender(formData.gender),
        ageFrom: formData.ageFrom,
        ageTo: formData.ageTo
      });
      setCreatedCategoryName(categoryCreateResponse.data.category.name);
      setShowPositiveAlert(true);
      setLoading(false)
      setTimeout(() => {
        setShowPositiveAlert(false);
      }, 1000);
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error(error.response.data);
      setErrorMessage(formatErrorMessage(error.response.data.paramMap));
      setShowNegativeAlert(true);
      setLoading(false)
    }
  };

  const formatErrorMessage = (paramMap) => {
    let errorMessage = "Validation failed:" +
      "\n";
    for (const key in paramMap) {
      if (paramMap.hasOwnProperty(key)) {
        errorMessage += `\n${key}: ${paramMap[key]}\n`; // Add newline characters after each key-value pair
      }
    }
    return errorMessage;
  };

  return (
    <div className="new">
      <MainSidebar/>
      <div className="newFormContainer" style={{paddingTop: "2vh"}}>
        <div className="wrapper">
          <div className="header">
            <h4>{"Nová kategorie"}</h4>
          </div>
          <div className="dataSection" style={{paddingBottom: "10px", borderBottom: "none"}}>
            <div className="right">
              <form onSubmit={handleSubmit}>
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    {input.type === "select" ? (
                      <FormControl variant="standard" style={{ width: '100%', border: "0.5px solid rgb(230, 227, 227)", borderRadius: "4px", backgroundColor: "white" }}>
                        <Select
                          disableUnderline={true}
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          onChange={(e) => handleInputChange(e, input.id)}
                          value={formData[input.id] || ""}
                          style={{textAlign: "center",}}
                          sx={{
                            backgroundColor: "#f5f5f5",
                            border: "0.5px solid rgb(180, 178, 178)"
                          }}
                        >
                          {input.options.map((option, index) => (
                            <MenuItem key={index} value={option}>{option}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <input
                        type={input.type}
                        placeholder={input.placeholder}
                        value={formData[input.id] || ""}
                        onChange={(e) => handleInputChange(e, input.id)}
                      />
                    )
                    }
                  </div>
                ))}
                <Button type="submit" disabled={loading}>Vytvořit</Button>
              </form>
            </div>
          </div>
          {showPositiveAlert && (
            <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success">
              Kategorie {createdCategoryName} úspěšně přidána!
            </Alert>

          )}
          {showNegativeAlert && (
            <Alert severity="error" onClose={() => {
              setShowNegativeAlert(false)
            }}>
              <AlertTitle>Kategorii se nepodařilo vytvořit.</AlertTitle>
              {errorMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewCategory;
